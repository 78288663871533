html, body {
  height: 100%;
  margin: 0;
  user-select: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

#root {
  min-height: 100%; 
  margin-bottom: -50px;
}

footer {
  height: 50px;
  width: 100%;
  font-size: 14px;
}

footer a {
  color: white;
  text-decoration: none;
}

h3 {
  margin: 0;
}

section {
  margin: 30px 0 0;
}

nav {
  margin: 50px 0 0;
}

.app {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
}

.menu .btn{
  display: block;
  color: white;
  text-decoration: none;
}

.btn {
  cursor: pointer;
  font-size: calc(10px + 2vmin);
  margin: 10px;
  padding: calc(5px + 1vmin) calc(20px + 1vmin);
  border-radius: 10%;
  transition-duration: 0.4s;
  background-color: #888;
}

.btn:hover {
  border: 2px solid #888;
  background-color: #ccc;
}

.btn.disable {
  cursor: not-allowed;
  background-color: #888;
}

.btn.green {
  background-color: #4CAF4F;
}

.btn.green:hover {
  border: 2px solid #4CAF4F;
  background-color: #77c57ae0;
}

.btn.blue {
  background-color: #048CBA;
}

.btn.blue:hover {
  border: 2px solid #048CBA;
  background-color: #48abcc;
}

.btn.large {
  font-size: calc(10px + 3vmin);
  padding: calc(5px + 2vmin) calc(20px + 2vmin);
}

.icon {
  margin: 0 2px;
  width: 16px;
  height: 16px;
}

.icon-github {
  content: url(../images/github-brands.svg);
}

.icon-blog {
  content: url(../images/blog-solid.svg);
}

.icon-backspace {
  content: url(../images/backspace-solid.svg);
}

.icon-check {
  content: url(../images/check-solid.svg);
}

ul {
  padding: 0;
}

li {
  display: block;
  margin: 10px;
  position: relative;
}

.dropbtn {
  margin: 10px 10px 1px;
  width: 110px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
  background: url(../images/language-translator.svg) 96% / 15% no-repeat #eee;
}

.dropdown {
  position: relative;
  float: right;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin: 0px 10px;
  font-size: 14px;
}

.dropdown-content a {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #4CAF4F;}