.header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.header .close {
    margin: 35px 15px;
}

.questionNumber {
    position: absolute;
    top: 32px;
    left: 18px;
    padding: 6px;
    background-color: #048CBA;
    border-radius: 50%;
}

.question {
    margin-top: 80px;
    font-size: calc(10px + 15vmin);
}

.answer-method {
    width: 100vmin;
}

.answer-method.filling .question {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: calc(10px + 8vmin);
}

.choices {
    padding: 0;
    max-width: 800px;
}

.choices li {
    cursor: pointer;
    font-size: calc(10px + 4vmin);
    display: inline-block;
    border-radius: 10%;
    transition-duration: 0.4s;
    background-color: #ccc;
    color: black;
    border: 2px solid #048CBA;
    width: 30%;
    height: calc(50px + 5vmin);
}

.choices li span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.choices li:hover {
    background-color: #048CBA;
}

@media (hover: none) {
    .choices li:hover {
        background-color: #ccc;
    }
}

progress {
    height: 20px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    display: block;
}

progress::-webkit-progress-value {
    background: #5DB75D;
}

progress.warning::-webkit-progress-value {
    background: #EFAD4C;
    transition-duration: 0.4s;
}

progress.danger::-webkit-progress-value {
    background: #D9544E;
    transition-duration: 0.4s;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #282c34;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.modal-content table {
    width: 100%;
    padding-top: 20px;
}

.modal-footer {
    padding-top: 30px;
}

.correct b::before {
    color: greenyellow;
    content: "O";
}

tr.correct {
    background-color: forestgreen;
}

tr.incorrect {
    background-color: rosybrown;
}

.incorrect b::before {
    color: red;
    content: "X";
}

.close {
    float: right;
    margin: -10px;
    border: 1px solid #ccc;
    padding: 0px 10px 5px 10px;
    cursor: pointer;
}

.close:hover {
    background-color: #888;
}

@keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    to {
        opacity: 1.0;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    to {
        opacity: 1.0;
    }
}

.blink {
    animation: fade 600ms infinite;
    -webkit-animation: fade 600ms infinite;
}

p {
    font-size: calc(10px + 5vmin);
}

.sections {
    padding: 0px;
    margin: 0;
    max-width: 800px;
}

.sections li {
    display: inline-block;
}

.sections label {
    padding: 0;
    cursor: pointer;
}

.sections input[type=checkbox] {
    display: none;
}

.sections input[type=checkbox]+span {
    display: inline-block;
    background-color: #888;
    padding: 10px calc(10px + 5vmin);
    border-radius: 10%;
    transition-duration: 0.4s;
}

.sections input[type=checkbox]:checked+span {
    background-color: #48abcc;
}

.sections input[type=checkbox]:checked+span:after {
    content: "";
    width: 5px;
    height: 10px;
    position: absolute;
    border: 2px solid #00ff08;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: calc(10px + 1vmin);
    left: calc(5px + 2vmin);
}

.incorrect .clock {
    margin: 0;
    width: 100px;
}

.slider {
    -webkit-appearance: none;
    width: 60%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #48abcc;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #48abcc;
    cursor: pointer;
}